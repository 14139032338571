/* General Reset */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.6;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #141414;
}

/* Main Container */
.main-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  min-height: 100vh;
  background-color: black;
  color: white;
  padding: 2rem 1rem;
}

/* Content Wrapper */
.construction-content {
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* Logo */
.logo-image {
  max-width: 220px;
  width: 80%;
  margin-bottom: 2rem;
  height: auto;
}

/* Text Content */
.content-container {
  width: 100%;
  padding: 0 1.5rem;
}

.title {
  font-size: clamp(1.8rem, 5vw, 3rem);
  margin-bottom: 1rem;
  color: white;
  line-height: 1.2em;
}

.subtitle {
  font-size: clamp(1.1rem, 3vw, 1.3rem);
  margin-bottom: 2.5rem;
  color: #cccccc;
}

/* Contact Section */
.contact-info {
  margin-top: 2rem;
  width: 100%;
}

.contact-info h2 {
  margin-bottom: 1rem;
  font-size: clamp(1.2rem, 4vw, 1.5rem);
  color: white;
}

/* Contact Items */
.contact-item {
  margin: 1.2rem 0;
}

.contact-link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  color: white;
  text-decoration: none;
  font-size: clamp(1rem, 3vw, 1.2rem);
  padding: 0.8rem;
  border-radius: 8px;
  transition: all 0.2s ease;
  background: rgba(255, 255, 255, 0.1);
}

.contact-link:hover, .contact-link:focus {
  background: rgba(255, 255, 255, 0.2);
}

.contact-icon {
  width: 24px;
  height: 24px;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .construction-content {
    max-width: 95%;
  }

  .content-container {
    padding: 0 1rem;
  }

  .contact-link {
    flex-direction: column;
    text-align: center;
  }

  .contact-icon {
    margin-bottom: 0.5rem;
  }
}

@media screen and (max-width: 480px) {
  .main-container {
    padding: 1rem;
  }

  .logo-image {
    max-width: 160px;
  }
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  .contact-link {
    transition: none;
  }
}