.main-container{
    background-color: #141414;
    display: flex;
    max-width: 480px;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    font-size: 24px;
    color: #000;
    font-weight: 500;
    text-align: center;
    line-height: 1.54;
    margin: 0 auto;
}

.guest-content-container {
    border-radius: 79px 0 0 0;
    background-color: #fcfcfc;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
  }
  
  .profileHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 40px;
    color: #fff;
    white-space: nowrap;
    line-height: 92.5%;
    padding: 32px;
  }
  
  .first-profile-item{
    margin-left: 10px;
    flex: 0 0 auto;
  }

  .first-profile-item:hover {
    cursor: pointer;
  }
  
  .second-profile-item {
    font: 40px/37px "Helvetica Neue", -apple-system, Roboto, Helvetica, sans-serif;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }
  
  .form {
    border-radius: 79px 0 0 0;
    position: relative;
    box-sizing: border-box;
    background-color: #fcfcfc;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    /* margin-top: 40px; */
    padding: 60px;
  }

  
  .infoBlock {
    border-radius: 9px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;
    padding: 9px 12px 16px;
    width: 100%;
  }
  
  .infoTitle {
    font-size: 12px;
    line-height: 308%;
    color: #000;
    font-family: "Helvetica Neue", -apple-system, Roboto, Helvetica, sans-serif;
  }
  
  .infoValue {
    font-size: 16px;
    line-height: 231%;
    color: #9c9c9c;
    margin-top: 8px;
    font-family: "Helvetica Neue", -apple-system, Roboto, Helvetica, sans-serif;
  }
  
  .actionBlock {
    border-radius: 9px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    background-color: #141414;
    color: #fff;
    text-align: center;
    width: 100%;
    margin-top: 49px;
    padding: 19px 25px;
    font-size: 24px;
    line-height: 154%;
    font-family: "Helvetica Neue", -apple-system, Roboto, Helvetica, sans-serif;
  }
  
  .loginLink {
    position: absolute;
    bottom: 40px;
    color: #000;
    text-decoration: underline;
    text-align: center;
    width: 100%;
    font-size: 12px;
    line-height: 308%;
    font-family: "Helvetica Neue", -apple-system, Roboto, Helvetica, sans-serif;
    cursor: pointer;
  }
  